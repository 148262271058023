export const mergeColumns = (initialColumns = [], additionalConfigs = {}) => {
  const columns = initialColumns.slice();

  return columns.map((col) => {
    const columnState = additionalConfigs[col?.key]?.columnState;

    return {
      ...col,
      columnState,
    };
  });
};
