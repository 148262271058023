import React, { useMemo, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { Tooltip } from "antd";
import useMeasure from "react-use-measure";
import * as Popover from "@radix-ui/react-popover";
import * as colors from "utils/constants/colors";
import { parseTagsForInput } from "utils/parsers";
import { useTranslation } from "react-i18next";

const TAG_MAX_WIDTH = 130;
const LINE_WIDTH = 320;
const MAX_LINES = 2;
const FONT_SIZE = 11;
const LINE_HEIGHT = 18;
const LETTER_SPACING = 0.05;
const HORIZONTAL_PADDING = 12;
const VERTICAL_PADDING = 4;
const MARGIN_RIGHT = 5;
const BORDER = 1;
const AVERAGE_CHARACTER_WIDTH = 7;

export const Tag = styled.div`
  max-width: ${TAG_MAX_WIDTH}px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  border: ${BORDER}px solid ${colors.lightMidGray};
  box-sizing: border-box;
  border-radius: 13px;
  font-weight: 500;
  font-size: ${FONT_SIZE}px;
  line-height: ${LINE_HEIGHT}px;
  background: ${colors.lightWhite};
  color: ${colors.midGray};
  letter-spacing: ${LETTER_SPACING}px;
  padding: ${VERTICAL_PADDING}px ${HORIZONTAL_PADDING}px;
  display: inline-block;
  margin-right: ${MARGIN_RIGHT}px;
  cursor: pointer;

  ${(props) =>
    props.isHighlighted &&
    css`
      background-color: ${colors.lightPurpleGray};
    `}
`;

const TooltipTag = styled.div`
  font-size: 13px;
  line-height: 21px;
  color: ${colors.white};
`;

const TagsWrap = styled.div`
  width: 100%;
  max-height: 68px;
`;

const StyledPopoverContent = styled(Popover.Content)`
  padding: 0 16px 8px 16px;
  max-width: 300px;
  background-color: #f7f9ff;
`;

const StyledTitle = styled.div`
  padding: 5px 0;
  min-height: 32px;
  display: flex;
  align-items: center;
  color: #6a7494;
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  text-transform: uppercase;
`;

const getApproximateTagWidth = (tag) => {
  const approximateWidthOfCharacter =
    (AVERAGE_CHARACTER_WIDTH / 16) * FONT_SIZE;
  const letterSpacing = LETTER_SPACING * FONT_SIZE * (tag.length - 1);
  const contentLength =
    tag.length * approximateWidthOfCharacter + letterSpacing;
  const padding = 2 * HORIZONTAL_PADDING;
  const borders = 2 * BORDER;
  return contentLength + padding + borders + MARGIN_RIGHT;
};

const getVisibleTagsCount = (tags = [], availableWidth = LINE_WIDTH) => {
  const visible = [];
  const seeMoreTagWidth = getApproximateTagWidth("...");
  // TO DO: find a better solution, maybe add a predefined breakpoint
  let usedSpace = 2 * seeMoreTagWidth;

  const availableSpace = MAX_LINES * availableWidth;

  for (const tag of tags) {
    const tagWidth = getApproximateTagWidth(tag);

    if (usedSpace + tagWidth + seeMoreTagWidth <= availableSpace) {
      visible.push(tag);
      usedSpace += tagWidth;
    } else {
      break;
    }
  }

  return visible.length;
};

function renderFirstTags(keys) {
  return keys.map((tag, index) => {
    if (tag.length > 15) {
      return (
        <Tooltip key={index} title={tag}>
          <Tag>{tag}</Tag>
        </Tooltip>
      );
    }

    return <Tag key={index}>{tag}</Tag>;
  });
}

function renderTagsInTooltip(tag, index) {
  return <TooltipTag key={index}>{tag}</TooltipTag>;
}

const Tags = ({ tags, threshold = 1, className }) => {
  const tagsKeys = parseTagsForInput(tags);
  const firstKeys = tagsKeys.slice(0, threshold);
  const lastKeys = tagsKeys.slice(threshold, tagsKeys.length);

  return (
    <div className={className}>
      {renderFirstTags(firstKeys)}
      {tagsKeys.length > threshold && (
        <Tooltip placement="right" title={lastKeys.map(renderTagsInTooltip)}>
          <Tag>...</Tag>
        </Tooltip>
      )}
    </div>
  );
};

export const TagsV2 = ({
  tags = {},
  onSelect,
  highlighted = [],
  collisionBoundaryRef,
  portalRef,
}) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [ref, bounds] = useMeasure();
  const containerRef = useRef(null);

  const renderTag = (tag, index) => {
    const tagElem = (
      <Tag
        key={index}
        onClick={(ev) => {
          ev.stopPropagation();
          onSelect && onSelect(tag);
        }}
        isHighlighted={highlighted.includes(tag)}
      >
        {tag}
      </Tag>
    );

    if (tag.length > 15) {
      return (
        <Tooltip key={index} title={tag}>
          {tagElem}
        </Tooltip>
      );
    }

    return tagElem;
  };

  const tagsKeys = useMemo(() => {
    return parseTagsForInput(tags);
  }, [tags]);

  const keys = useMemo(() => {
    const visibleTagsCount = getVisibleTagsCount(tagsKeys, bounds.width) || 1;

    return {
      visible: tagsKeys.slice(0, visibleTagsCount),
      hidden: tagsKeys.slice(visibleTagsCount, tagsKeys.length),
    };
  }, [tagsKeys, bounds.width]);

  return (
    <TagsWrap ref={ref}>
      <div ref={containerRef}>
        {keys.visible.map(renderTag)}
        {keys.hidden.length > 0 && (
          <Popover.Root
            open={opened}
            onOpenChange={(open) => {
              setOpened(open);
            }}
          >
            <Popover.Trigger asChild>
              <Tag onClick={(ev) => ev.stopPropagation()}>...</Tag>
            </Popover.Trigger>
            <Popover.Portal container={portalRef}>
              <StyledPopoverContent
                sideOffset={5}
                side="right"
                align="start"
                collisionBoundary={collisionBoundaryRef}
                sticky="always"
                hideWhenDetached={true}
              >
                <StyledTitle>{t("Tags")}</StyledTitle>
                {keys.hidden.map(renderTag)}
              </StyledPopoverContent>
            </Popover.Portal>
          </Popover.Root>
        )}
      </div>
    </TagsWrap>
  );
};

export default Tags;
