import React, {
  useEffect,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { useTranslation } from "react-i18next";
import { Select, Tooltip, DatePicker, Input as AntInput } from "antd";
import styled from "styled-components";
import isEqual from "fast-deep-equal";
import { createTeleporter } from "react-teleporter";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import MapIcon from "assets/icons/map_icon.svg?react";
import BulletListIcon from "assets/icons/bullet_list_icon.svg?react";
import { default as SelectV2 } from "components/ui/Select";

import { getEntity } from "utils/entities";
import { gray } from "utils/constants/colors";
import { parseFilterTag } from "utils/parsers";

import {
  List,
  LoadAsYouScroll,
  SearchBar,
  Table,
  EmptyPage,
  EmptyResult,
  Pagination,
} from "modules/list/components";
import StartPage from "pages/clusters/new/components/StartPage";
import ListActions from "modules/list/actions";
import ColumnActions from "modules/list/columnActions";

import { FieldWrap, Error } from "components/styled/Field";
import { MaxTagPlaceholder } from "components/ui/Fields";
import Icon from "components/ui/Icon";
import {
  faInfoCircle,
  faAngleUp,
  faAngleDown,
} from "@fortawesome/pro-regular-svg-icons";
import {
  Checkbox as StyledCheckbox,
  CheckboxGroup as StyledCheckboxGroup,
} from "components/styled/Checkbox";
import RadioGroup from "components/ui/RadioGroup";

import { Dropdown, Tag } from "antd";
import { userSettingsCache } from "services/localstorage/cache";
import Ellipsis from "components/common/Ellipsis";
import Filters from "components/styled/Filters";
import { cellTypes } from "./CellTypes";
import { TextButton } from "components/ui/Button";

const TagsWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  align-items: center;
`;

const FilterTag = styled(Tag)`
  border-radius: 2px;
  padding: 4px 8px;
  background: #c3e8fc;
  border: 1px solid #c3e8fc;
  cursor: pointer;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TagLabel = styled.span`
  color: #3e4856;
  font-size: 14px;
  line-height: 22px;
`;

const TagsTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-right: 4px;
`;

const SearchIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  color: ${gray};
  margin-right: 8px;
`;

const ViewSwitchWrapper = styled.div`
  > ${FieldWrap} {
    margin: 0;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  svg {
    height: 12px;

    path {
      fill: #48438d;
    }
  }

  .ant-radio-button-wrapper-checked {
    background: #294e82;

    svg path {
      fill: #fff;
    }
  }
`;

// TODO: refactor this (it's from ui/Fields)
export function Field(Component) {
  function FieldComponent(
    { required = true, label, validation, ...rest },
    ref
  ) {
    let error = null;
    const { t } = useTranslation();

    if (validation) {
      error = validation[0];
    }

    function renderLabel() {
      if (!label) {
        return null;
      }
      return (
        <label>
          {label} {!required && t("(Optional)")}
        </label>
      );
    }

    return (
      <FieldWrap className={error && `has-${error.status}`}>
        {renderLabel()}
        <Component ref={ref} {...rest} validateStatus={error && error.status} />
        {error && <Error>{error.result}</Error>}
      </FieldWrap>
    );
  }

  FieldComponent.displayName = `Field(${Component.displayName})`;

  return React.forwardRef(FieldComponent);
}

export const ListContext = createContext({});

export function useList() {
  return useContext(ListContext);
}

export default function createList({
  initialQuery,
  parseItems,
  schema,
  actions,
  ...rest
}) {
  const listActions = new ListActions(
    actions || { ...rest, schema, initialQuery }
  );

  const columnActions = new ColumnActions();

  function ListModule({
    listState,
    children,
    initialize,
    fetchItems,
    module,
    ...rest
  }) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hasActiveFilters = useMemo(() => {
      if (!listState) {
        return false;
      }

      const query = listState.query;
      const defaultQuery = listState.defaultQuery;

      return query && defaultQuery ? !isEqual(query, defaultQuery) : false;
    }, [listState]);

    useEffect(initialize, [module]);

    if (listState === undefined) {
      return null;
    }

    return (
      <ListContext.Provider
        value={{ module, fetchItems, ...listState, hasActiveFilters, ...rest }}
      >
        {children}
      </ListContext.Provider>
    );
  }

  const selectorsCache = {};

  const addReduxContext = connect(
    (state, ownProps) => {
      let itemsSelector = selectorsCache[ownProps.module];
      if (!itemsSelector) {
        const getListOfItems = createSelector(
          (state) => state.list[ownProps.module]?.items || {},
          (state) => state.list[ownProps.module]?.pages || new Set(),
          (state) => state.list[ownProps.module]?.currentPageNumber,
          (items, pages, currentPageNumber) => {
            if (listActions.hasPagination) {
              return items[currentPageNumber] || [];
            }
            const listOfIds = [...pages].reduce((accumulator, page) => {
              return accumulator.concat(items[page] || []);
            }, []);

            return [...new Set(listOfIds)];
          }
        );
        let getItems = getListOfItems;
        if (schema) {
          getItems = getEntity(getListOfItems, schema);
        }
        itemsSelector = getItems;
        if (parseItems) {
          itemsSelector = createSelector(getItems, parseItems);
        }

        selectorsCache[ownProps.module] = itemsSelector;
      }

      return {
        listState: state.list[ownProps.module],
        items: itemsSelector(state),
      };
    },
    (dispatch, ownProps) => {
      function onColumnSort(sorter) {
        return (dispatch, getState) => {
          if (!sorter) {
            return;
          }

          const query = getState().list[ownProps.module]?.query || {};
          const { columnKey, order } = sorter;
          const sortField = order ? columnKey : "";
          const orderTypes = { ascend: "asc", descend: "desc" };
          const sortOrder = orderTypes[order] || "";

          dispatch(
            listActions.batchChangeQuery({
              query: {
                ...query,
                sortField,
                sortOrder,
              },
              module: ownProps.module,
            })
          );
        };
      }

      return {
        initialize: () => {
          if (ownProps.preventInitOnMount) {
            return;
          }
          dispatch(
            listActions.initialize(
              ownProps.module,
              initialQuery && initialQuery()
            )
          );
        },
        fetchItems: () => dispatch(listActions.fetchItems(ownProps.module)),
        nextPage: () => dispatch(listActions.nextPage(ownProps.module)),
        goToPage: (pageNumber) =>
          dispatch(
            listActions.goToPage({ module: ownProps.module, pageNumber })
          ),
        changeQuery: (name, value) => {
          if (name === "registry") {
            // TODO: this should not be here
            // it's specific to filters in profile pack selection
            userSettingsCache.set("selectedRegistry", value);
          }

          dispatch(
            listActions.changeQuery({ name, value, module: ownProps.module })
          );
        },
        onColumnSort: (arg1, arg2, sorter) => dispatch(onColumnSort(sorter)),
        onPageSizeChange: (_, newLimit) => {
          dispatch(
            listActions.onPageSizeChange({ module: ownProps.module, newLimit })
          );
        },
        toggleFiltersVisibility: () => {
          dispatch(listActions.toggleFiltersVisibility(ownProps.module));
        },
        columnActions: Object.keys(columnActions).reduce((acc, key) => {
          const method = columnActions[key];
          acc[key] = (payload = {}) =>
            dispatch(method({ ...payload, module: ownProps.module }));
          return acc;
        }, {}),
      };
    }
  );
  return addReduxContext(ListModule);
}

export function connectComponent(Component) {
  return function ConnectedListingComponent(props) {
    const context = useContext(ListContext);
    return <Component {...context} {...props} />;
  };
}

export function connectFiltersComponent(Component) {
  return function ConnectedComponent(props) {
    const name = props.name;
    const context = useContext(ListContext);
    return (
      <Component
        value={context.query[name]}
        onChange={context.changeQuery.bind(null, name)}
        {...props}
      />
    );
  };
}

// TODO: refactor this (it's from ui/Fields)
const LabelOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .anticon.anticon-info-circle {
    margin-left: 11px;
  }
`;

// TODO move this in a different component
function SelectWithOptions({ options = [], searchBy, ...rest }) {
  function renderOption(option) {
    if (option.isGroup) {
      return (
        <Select.OptGroup label={option.label} key={option.value}>
          {option.options.map(renderOption)}
        </Select.OptGroup>
      );
    }
    return (
      <Select.Option
        key={option.value}
        value={option.value}
        {...{ [searchBy]: option[searchBy] }}
      >
        <LabelOption>
          <span title={typeof option.label === "string" && option.label}>
            {option.label}
          </span>{" "}
          {option.description && (
            <Tooltip title={option.description} placement="right">
              <Icon awesome={faInfoCircle} />
            </Tooltip>
          )}
        </LabelOption>
      </Select.Option>
    );
  }

  return (
    <Select
      maxTagPlaceholder={(values) => (
        <MaxTagPlaceholder omittedValues={values} />
      )}
      optionFilterProp={searchBy}
      {...rest}
    >
      {options.map(renderOption)}
    </Select>
  );
}

function ConnectedInput({ searchPrefix, name, ...rest }) {
  const context = useContext(ListContext);
  return (
    <AntInput
      value={context.query[name]}
      onChange={(ev) => context.changeQuery(name, ev?.target?.value)}
      prefix={searchPrefix ? <SearchIcon icon={faSearch} /> : null}
      name={name}
      {...rest}
    />
  );
}

function ConnectedCheckbox({ searchPrefix, name, ...rest }) {
  const context = useContext(ListContext);
  return (
    <StyledCheckbox
      checked={context.query[name]}
      onChange={(ev) => context.changeQuery(name, ev?.target?.checked)}
      prefix={searchPrefix ? <SearchIcon icon={faSearch} /> : null}
      name={name}
      {...rest}
    />
  );
}

export const DropdownOverlaySlot = createTeleporter();

function Filter({
  group,
  index,
  selectedTag,
  hasInnerDropdownOpen,
  onFilterTagRemove,
  onEditTag,
}) {
  const [isVisible, setIsVisible] = useState(false);

  const label = useMemo(() => {
    return group.conditions
      .map((condition) => parseFilterTag(condition))
      .join(", ");
  }, [group.conditions]);

  useEffect(() => {
    setIsVisible(selectedTag === group.guid);
  }, [selectedTag, group.guid]);

  const onVisibleChange = (visible) => {
    if (!hasInnerDropdownOpen) {
      setIsVisible(visible);
    }
  };

  return (
    <Dropdown
      key={group.guid}
      trigger={["click"]}
      overlay={isVisible ? <DropdownOverlaySlot.Target /> : <div />}
      visible={isVisible}
      onVisibleChange={onVisibleChange}
    >
      <FilterTag
        closable
        onClose={onFilterTagRemove.bind(null, index)}
        onClick={onEditTag.bind(null, group.guid)}
      >
        <TagLabel>{label}</TagLabel>
      </FilterTag>
    </Dropdown>
  );
}

function ConnectedAppliedFiltersTags({
  name,
  cache,
  cacheKey,
  onEditTag,
  selectedTag,
  hasInnerDropdownOpen,
}) {
  const { t } = useTranslation();
  const context = useContext(ListContext);
  const filterGroups = useMemo(
    () => context.query[name] || [],
    [context.query, name]
  );

  useEffect(() => {
    cache.set(cacheKey, filterGroups);
  }, [filterGroups, cache, cacheKey]);

  const onFilterTagRemove = (index) => {
    context.changeQuery(
      name,
      [...filterGroups].filter((_, groupIndex) => groupIndex !== index)
    );
  };

  const renderTag = (group, index) => {
    return (
      <Filter
        key={group.guid}
        group={group}
        index={index}
        selectedTag={selectedTag}
        onFilterTagRemove={onFilterTagRemove}
        onEditTag={onEditTag}
        hasInnerDropdownOpen={hasInnerDropdownOpen}
      />
    );
  };

  return (
    <TagsWrapper>
      <TagsTitle>{t("Filter by: ")}</TagsTitle>
      {filterGroups.length ? (
        filterGroups.map(renderTag)
      ) : (
        <span>{t("none")}</span>
      )}
    </TagsWrapper>
  );
}

function ConnectedListViewSwitch({ name }) {
  const { t } = useTranslation();
  const context = useContext(ListContext);

  return (
    <ViewSwitchWrapper>
      <StyledRadioGroup
        options={[
          {
            label: (
              <Tooltip title={t("Cluster List View")} placement="bottom">
                <BulletListIcon />
              </Tooltip>
            ),
            value: "list",
          },
          {
            label: (
              <Tooltip title={t("Clusters Map View")} placement="bottom">
                <MapIcon />
              </Tooltip>
            ),
            value: "map",
          },
        ]}
        optionType="button"
        onChange={(value) => context.changeQuery(name, value)}
        value={context.query[name]}
      />
    </ViewSwitchWrapper>
  );
}

const FilterSelectionsWrap = styled.div`
  background: #fff;
  min-height: 48px;
  border-bottom: 1px solid #dee1ea;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  padding: 8px 12px 0 12px;
  color: #6a7494;
  flex-shrink: 0;
  width: 100%;

  &:empty {
    min-height: 0;
    border-bottom: 0 none;
  }

  .ant-tag {
    background: #dcdaf9;
    border-radius: 999px;
    font-size: 12px;
    line-height: 24px;
    border: 0 none;
    margin-bottom: 8px;
    max-width: 320px;
    display: inline-flex;
    overflow: hidden;
  }

  ${Filters.FormWrap} & {
    border: 0 none;
    padding-left: 0;
    padding-right: 0;
  }
`;

const FilterSelectionLabel = styled.span`
  margin-right: 8px;
`;

function extractOptionLabel(value, options) {
  if (!options) {
    return value;
  }

  const option = options.find((option) => option.value === value);
  return option ? option.label : value;
}

function FilterSelections({ labels = {}, options = {} }) {
  const context = useContext(ListContext);
  if (!context.showFilterSelections) {
    return null;
  }

  if (isEqual(context.query, context.defaultQuery)) {
    return null;
  }

  return (
    <FilterSelectionsWrap>
      {Object.keys(context.query).map((key) => {
        if (!labels[key]) {
          return null;
        }
        let selections = context.query[key];
        if (!selections || !selections?.length) {
          return null;
        }

        if (!Array.isArray(selections)) {
          selections = [selections];
        }

        return (
          <div key={key}>
            <FilterSelectionLabel>{labels[key]}: </FilterSelectionLabel>
            {selections.map((value) => {
              const tag = extractOptionLabel(value, options[key]);
              return (
                <Tag
                  key={value}
                  closable
                  onClose={() => {
                    const query = context.query[key];
                    let newValue;
                    if (Array.isArray(query)) {
                      newValue = query.filter((val) => val !== value);
                    }

                    if (typeof query === "string") {
                      newValue = "";
                    }

                    context.changeQuery(key, newValue);
                  }}
                >
                  <Ellipsis title={tag}>{tag}</Ellipsis>
                </Tag>
              );
            })}
          </div>
        );
      })}
    </FilterSelectionsWrap>
  );
}

const onColumnHeaderResize = (column, onColumnResize) => {
  return {
    column,
    onResize: ({ width }) => {
      const { key, type } = column;
      const cellDefaults = cellTypes[type] || {};
      const { minWidth, maxWidth } = cellDefaults;
      const newWidth =
        width < minWidth ? minWidth : width > maxWidth ? maxWidth : width;
      onColumnResize({ key, newWidth });
    },
  };
};

function createColumnsOptions({
  columns = [],
  columnSettings = {},
  onColumnResize,
  resizable = false,
  tableContainerRef,
  stackingContextsRefs,
}) {
  const { hidden = [], pinned = [], resized = {} } = columnSettings;
  const newColumns = columns.slice()?.map((col) => {
    const { columnState = {}, type = "", width, key } = col;
    const cellDefaults = cellTypes[type] || {};
    const cellResized = resized[key] || "";

    const options = {
      label: col.title,
      hidden: hidden?.includes(key),
      fixed: columnState?.fixed || pinned?.includes(key),
      width: cellResized || cellDefaults?.defaultWidth || width,
      ...(columnState ? { locked: columnState?.locked } : {}),
    };

    function newRender(...args) {
      const { fixed: pinned, ...rest } = options;
      return col.render(...args, {
        ...rest,
        pinned,
        resizable,
        refs: {
          wrap: tableContainerRef,
          stackingContextsRefs,
        },
      });
    }

    return {
      ...col,
      ...options,
      ...(col.render ? { render: newRender } : {}),
      ...(resizable
        ? {
            onHeaderCell: (...args) =>
              onColumnHeaderResize(...args, onColumnResize),
          }
        : {}),
    };
  });

  return newColumns;
}

function TableWithColumnsManager({ columns = [], ...rest }) {
  const tableContainerRef = React.useRef(null);
  const stackingContextsRefs = React.useRef({});
  const { resizable, columnActions = {} } = rest;
  const { getColumnsSettings = () => ({}), onColumnResize = () => {} } =
    columnActions;
  const columnSettings = getColumnsSettings();
  const newColumns = createColumnsOptions({
    columns,
    columnSettings,
    onColumnResize,
    resizable,
    tableContainerRef,
    stackingContextsRefs,
  });

  return (
    <Table
      {...rest}
      columns={newColumns}
      refs={{ stackingContextsRefs, tableContainerRef }}
    />
  );
}

const FiltersToggleButton = styled(TextButton)`
  color: rgba(31, 38, 60);

  &:hover,
  &:focus {
    color: rgba(31, 38, 60);
  }
`;

function FiltersToggle({ visibleKeys = [] }) {
  const context = useList();
  const { query, defaultQuery } = context;

  const hasFiltersApplied = useMemo(() => {
    if (isEqual(query, defaultQuery)) {
      return false;
    }

    return Object.keys(query)
      .filter((key) => !isEqual(query[key], defaultQuery?.[key]))
      .some((key) => visibleKeys.includes(key));
  }, [query, defaultQuery, visibleKeys]);

  if (!hasFiltersApplied) {
    return null;
  }

  return (
    <FiltersToggleButton onClick={() => context.toggleFiltersVisibility()}>
      <Icon awesome={context.showFilterSelections ? faAngleUp : faAngleDown} />
    </FiltersToggleButton>
  );
}

export const Blocks = {
  List: connectComponent(List),
  LoadAsYouScroll: connectComponent(LoadAsYouScroll),
  Search: connectComponent(SearchBar),
  Table: connectComponent(TableWithColumnsManager),
  EmptyPage: connectComponent(EmptyPage),
  StartPage: connectComponent(StartPage),
  EmptyResult: connectComponent(EmptyResult),
  Pagination: connectComponent(Pagination),
  FilterSelections: connectFiltersComponent(FilterSelections),
  FiltersToggle: connectComponent(FiltersToggle),
  FilterFields: {
    Checkbox: Field(ConnectedCheckbox),
    Input: Field(ConnectedInput),
    SelectV2: connectFiltersComponent(SelectV2),
    CheckboxGroup: connectFiltersComponent(Field(StyledCheckboxGroup)),
    Select: connectFiltersComponent(Field(SelectWithOptions)),
    DatePicker: connectFiltersComponent(Field(DatePicker)),
    RangePicker: connectFiltersComponent(Field(DatePicker.RangePicker)),
    AppliedFiltersTags: connectFiltersComponent(ConnectedAppliedFiltersTags),
    ListViewSwitch: connectFiltersComponent(ConnectedListViewSwitch),
  },
};
